<template>
  <div class="field">
    <div class="quiz-question-title" :class="smallClass">
      {{ title }}
    </div>
    <component
      v-for="answer in quizAnswers"
      :id="answer.id"
      :key="answer.id"
      :ref="`answer-${answer.id}`"
      :is="answerComponent(answer)"
      :answer="answer"
      :current-response="currentResponse(answer.id)"
      :is-small="isSmall"
      @uncheckOtherRadios="uncheckOtherRadios"
    ></component>
  </div>
</template>

<script>
import QuizCheckbox from "@/web/components/quizzes/input/QuizCheckbox";
import QuizRadio from "@/web/components/quizzes/input/QuizRadio";
import QuizTextInput from "@/web/components/quizzes/input/QuizTextInput";
import Constants from "@/web/constants";

export default {
  name: "QuizQuestion",

  props: ["quizQuestion", "currentResponses", "isSmall"],

  computed: {
    title() {
      return this.quizQuestion.question;
    },

    quizAnswers() {
      return this.quizQuestion.survey_quiz_answers;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    answerComponent(answer) {
      if (answer.mode === Constants.QUIZ_ANSWER_MODE.RADIO) {
        if (this.quizQuestion.mode === Constants.QUIZ_ANSWER_MODE.RADIO) {
          return QuizRadio;
        } else {
          return QuizCheckbox;
        }
      } else {
        return QuizTextInput;
      }
    },

    uncheckOtherRadios(answerId) {
      const otherRadios = Object.values(this.$refs).map(answerRef => (answerRef.length && answerRef[0]) || null);
      otherRadios.filter(radio => radio !== null && radio.answer.id !== answerId).forEach(radio => radio.uncheck());
    },

    getAllResponses() {
      const responses = Object.values(this.$refs).map(answerRef => (answerRef.length && answerRef[0].getResponse()) || null);
      const filteredResponses = responses.filter(response => response !== null);
      if (filteredResponses.length) {
        return filteredResponses;
      } else {
        return null;
      }
    },

    currentResponse(answerId) {
      const response = this.currentResponses && this.currentResponses.filter(response => response.answerId === answerId);
      if (response && response.length) {
        return response[0];
      } else {
        return null;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
