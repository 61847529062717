<template>
  <div class="field mb-3">
    <h3 class="title is-6">{{ title }}</h3>
    <div class="control mb-1">
      <textarea class="textarea" v-model="text" :class="smallClass"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuizTextInput",

  props: {
    answer: {
      type: Object,
      default: null,
    },

    currentResponse: {
      type: Object,
      default: null,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      text: "",
    };
  },

  mounted() {
    if (this.currentResponse) {
      this.text = this.currentResponse.responseText;
    }
  },

  computed: {
    title() {
      return this.answer.answer;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    getResponse() {
      if (this.text) {
        return {questionId: this.answer.survey_quiz_question_id, answerId: this.answer.id, responseText: this.text};
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped></style>
