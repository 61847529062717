<template>
  <div class="mt-1">
    <div class="quiz-answer-container" :class="classes" @click="onClick">
      <checkbox v-model="isChecked" :size="checkboxSize" class="mr-1"></checkbox>
      <p class="answer-text unselectable" :class="classes">{{ text }}</p>
    </div>
  </div>
</template>

<script>
import Checkbox from "@/shared/components/form/Checkbox";

export default {
  name: "QuizCheckbox",

  components: {Checkbox},

  props: {
    answer: {
      type: Object,
      default: null,
    },
    currentResponse: {
      type: Object,
      default: null,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isChecked: false,
    };
  },

  mounted() {
    if (this.currentResponse) {
      this.isChecked = true;
    }
  },

  computed: {
    text() {
      return this.answer.answer;
    },

    classes() {
      return {
        selected: this.isChecked,
        "is-small": this.isSmall,
      };
    },

    checkboxSize() {
      if(this.isSmall) {
        return "20px";
      } else {
        return "30px";
      }
    }
  },

  methods: {
    onClick() {
      this.isChecked = !this.isChecked;
    },

    getResponse() {
      if (this.isChecked) {
        return {questionId: this.answer.survey_quiz_question_id, answerId: this.answer.id};
      } else {
        return null;
      }
    },
  },
};
</script>
